import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { G8Manager } from '@ttstr/components';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

const G8Page: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="g8-page-content text-center">
      <Helmet>
        <title>{t('G8.TITLE')}</title>
        <body className="g8-page" />
      </Helmet>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <img className="g8-logo mb-5" alt="Logo" src={require('./assets/images/majan_logo.png')} loading="lazy" />
          <G8Manager />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(G8Page);
